<template>

<div class="connectedgroups">

	<h5 class="connectedgroups-title text-block" :class="{'is-first': isFirst}">{{ item.content }}</h5>

	<app-text-group v-for="(group, index) in groups" :key="index" :item="group" />

</div>

</template>

<script>

export default {

	props: ['item', 'isFirst'],

	computed: {

		groups: function() {

			var matchedGroups = []
			var texts = []
			var groups = []

			if (this.$route.params.location) {

				texts = this.$_.pluck(this.$_.filter(this.$store.getters['texts/all'], function(text) {

					return parseInt(this.$route.params.location) === text.location

				}.bind(this)), 'id')

				matchedGroups = this.$_.pluck(this.$_.filter(this.$store.getters['groups/all'], function(group) {

					return this.$_.intersection(group.texts, texts).length && group.visible && group.category

				}.bind(this)), 'id')

			} else {

				//
				
			}

			this.$_.each(matchedGroups, function(id) {

				groups.push({
					group: id
				})

			})

			return groups

		}

	}

}

</script>

<style scoped>

.connectedgroups-title {
	color: #707070;
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-top: 30px;
	margin-bottom: 10px;
	font-weight: 300;
	font-family: Roboto Slab,serif;
}

</style>
