<template>

<div class="divide" />

</template>

<script>

export default {

}

</script>

<style scoped>

.divide {
	width: 1px;
	height: 32px;
	background-color: #eee;
	margin: 8px 10px;
}

</style>
