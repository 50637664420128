<template>

<app-page-content-section :is-divided="isDivided">
	
	<router-link :to="{name: route, params: params}" class="link">

		<app-icon :icon="icon" class="link-icon" />

		<div class="link-text">{{ title }}</div>
		<div class="link-count" v-if="count != undefined">{{ count }}</div>

		<app-icon icon="chevron.right" class="link-chevron" />

	</router-link>

</app-page-content-section>

</template>

<script>

export default {

	props: ['icon', 'isDivided', 'title', 'count', 'route', 'params']

}

</script>

<style scoped>

.link {
	display: flex;
	align-items: center;
	color: #4082d3;
	padding: 20px;
}

.link-text {
	flex-grow: 1;
	padding: 0px 20px;
	font-size: 16px;
	font-weight: 400;
}

.link-count {
	background-color: #4082d3;
	color: #fff;
	padding: 6px 8px;
	border-radius: 4px;
	margin: 0px 20px;
}

.link-icon {
	font-size: 24px;
}

.link-chevron {
	font-size: 24px;
}

</style>
