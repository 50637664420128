<template>
	
<div class="title">

	<div class="title-text" v-html="text"></div>
	<div class="title-subtitle" v-if="subtitle">{{ subtitle }}</div>

</div>

</template>

<script>

export default {

	props: ['text', 'subtitle']

}

</script>

<style scoped>

.title {
	font-size: 16px;
	font-weight: 300;
	flex-grow: 1;
	color: #0b234e;
}

.title >>> b {
	background-color: #4082d3;
	color: #fff;
	border-radius: 4px;
	margin-right: 1px;
}

.title-subtitle {
	font-size: 12px;
	color: #666;
}

</style>
