import slugify from "slugify"
import nl2br from 'nl2br'

export default {

	computed: {

		$loggedin: function() {

			return this.$store.getters['key']

		},

		$context: function() {

			return this.$store.getters['context']

		}

	},

	methods: {

		$nl2br: function(text) {

			return nl2br(text)

		},

		$log: function(e) {

			console.log(e)

		},

		$highlight: function(text, match) {

			if (!match) return text

			var altText = slugify(text, {
				replacement: ' ',
				lower: true
			})
	
			match = slugify(match, {
				replacement: ' ',
				lower: true
			})

			var pos = altText.indexOf(match)
			var len = match.length


			return text.slice(0, pos) + '<b>' + text.slice(pos, pos + len) + '</b>' + text.slice(pos + len)

		}

	}

}