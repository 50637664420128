import _ from 'underscore'

export default {

	beforeCreate: function() {

		const options = this.$options

		_.each(['_', 'store', 'live', 'constants', 'util', 'moment', 'pubsub', 'api'], function(key) {

			if (options[key]) {
				this['$' + key] = options[key]
			} else if (options.parent && options.parent['$' + key]) {
				this['$' + key] = options.parent['$' + key]
			}

		}.bind(this))

	}

}