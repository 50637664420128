<template>

<div class="tick text-block" :class="{'is-linked': item.group}">

	<div class="tick-icon"></div>
	<h3 class="tick-text" v-html="title"></h3>

</div>

</template>

<script>

export default {

	props: ['item'],

	computed: {

		title: function() {

			return this.item.content || this.group.title

		},

		group: function() {

			return (this.item.group) ? this.$store.getters['groups/find'](this.item.group) : {
				title: 'Sample text'
			}

		}

	}

}

</script>

<style scoped>

.tick {
	display: flex;
	flex-direction: row;
}

.tick-icon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	flex-shrink: 0;
	background-size: 17px 17px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url(~@/assets/text/cross.png);
}

.tick-text {
	color: #707070;
	font-size: 18px;
	flex-grow: 1;
	line-height: 24px;
	margin-bottom: 5px;
}

.tick.is-linked {
	text-decoration: underline;
}

.tick-text >>> a {
	color: #4082d3;
}

</style>
