<template>

<div class="section" :class="{'is-padded': isPadded, 'has-title': title, 'is-divided': isDivided, 'is-grow': grow}">

	<div class="section-title" v-if="title">{{ title }}</div>

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['isPadded', 'isDivided', 'grow', 'title']

}

</script>

<style scoped>

.section {
	flex-shrink: 0;
}

.section.has-title {
	margin-bottom: 10px;
}

.section.is-grow {
	flex-grow: 1;
}

.section.is-padded {
	padding: 20px;
}

.section.is-divided {
	border-bottom: 1px solid #eee;
}

.section-title {
	font-size: 16px;
	font-weight: 300;
	color: #0b234e;
	background-color: #fff;
	padding: 0px 20px 10px 20px;
	width: 160px;
}

</style>
