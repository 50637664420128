<template>

<div class="head">

	<router-link :to="{name: back, params: params}" v-if="back"><app-icon icon="chevron.left" class="head-back" v-tooltip="'Back'" /></router-link>

	<div class="head-title">

		<div class="head-title-text">{{ title }}</div>
		<div class="head-title-subtext" v-if="count !== undefined">{{ count }}<template v-if="noun && back"> {{ noun }}<template v-if="count !== 1">s</template></template> found</div>

	</div>

	<div class="head-actions">

		<slot></slot>

	</div>

</div>

</template>

<script>

export default {

	props: ['title', 'count', 'back', 'noun', 'params']

}

</script>

<style scoped>

.head {
	position: absolute;
	padding: 10px 20px 10px 20px;
	top: 0px;
	left: 0px;
	right: 0px;
	height: 68px;
	display: flex;
	z-index: 2;
	align-items: center;
}

.head-back {
	font-size: 24px;
	margin-right: 20px;
	flex-shrink: 0;
	color: #0b234e;
	cursor: pointer;
}

.head-title {
	flex-grow: 1;
}

.head-title-text {
	font-size: 24px;
	font-weight: 400;
	color: #0b234e;
}

.head-title-subtext {
	font-size: 14px;
	font-weight: 300;
	color: #0b234e;
	margin-top: 4px;
}

.head-actions {
	display: flex;
	flex-shrink: 0;
	justify-content: flex-end;
}

</style>
