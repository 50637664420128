import '@/assets/reset.css'

import Vue from 'vue'
import App from './App.vue'

import _ from 'underscore'
import tooltip from 'v-tooltip'
import autogrow from 'vue-textarea-autogrow-directive'
import VueLazyload from 'vue-lazyload'
import moment from 'moment'
import constants from './constants'
import router from './router'
import live from './live'
import store from './store'
import pubsub from './pubsub'
import api from './api'
import util from './util'

import mixInjection from './mixin/injection'
import mixShortcuts from './mixin/shortcuts'
import mixWindow from './mixin/window'

import routerGuard from './router/guard' 

Vue.config.productionTip = false

var requireComponentGlobal = require.context('./components/common', true, /[A-Z]\w+\.(vue|js)$/)

requireComponentGlobal.keys().forEach(function(filename) {

	var componentConfig = requireComponentGlobal(filename)
	var componentName = 'app' + filename.replace(/\//g, '-').replace('.vue', '').replace('.', '').toLowerCase()

	Vue.component(
		componentName,
		componentConfig.default
	)

})

router.beforeEach(routerGuard)

Vue.use(tooltip)
Vue.use(autogrow)
Vue.use(VueLazyload)

Vue.mixin(mixWindow)
Vue.mixin(mixInjection)
Vue.mixin(mixShortcuts)

Vue.directive('bgimage', {

	bind: function (el, binding) {

		if (binding.value !== undefined && binding.value) {

			var file = (binding.value.indexOf('data:') === 0 || binding.value.indexOf('https:') === 0) ? binding.value : store.getters['config'].base + binding.value

			el.style.backgroundImage = (binding.value) ? 'url(' + file + ')' : false

		}

	},

	update: function (el, binding) {

		if (binding.value !== undefined && binding.value) {

			var file = (binding.value.indexOf('data:') === 0 || binding.value.indexOf('https:') === 0) ? binding.value : store.getters['config'].base + binding.value

			el.style.backgroundImage = (binding.value) ? 'url(' + file + ')' : false

		}

	}

})

Vue.directive('bgcolor', {

	bind: function (el, binding) {

		el.style.backgroundColor = (binding.value) ? '#' + binding.value : false

	},

	update: function (el, binding) {

		el.style.backgroundColor = (binding.value) ? '#' + binding.value : false

	}

})

Vue.filter('formatDate', function (value, format) {

	return (value) ? moment.unix(value).utc().format(format) : ''

})

new Vue({
	_,
	moment,
	router,
	constants,
	util,
	live,
	store,
	api,
	pubsub,
	render: h => h(App),
	created: function() {

		pubsub.$emit('window.initiated')

	}
}).$mount('#app')
