<template>

<router-link :to="{name: route, params: {text: item.id}}" class="text drag-handle" v-tooltip.top-start="'Click to edit, drag to re-order'" :class="{'is-preview': isPreview, 'is-first': isFirst, 'is-last': isLast}">

	<app-text-title :is-first="isFirst" v-if="item.type === $constants.text.type.title" :item="item" />
	<app-text-tick v-if="item.type === $constants.text.type.tick" :item="item" />
	<app-text-cross v-if="item.type === $constants.text.type.cross" :item="item" />
	<app-text-check v-if="item.type === $constants.text.type.check" :item="item" />
	<app-text-checklist v-if="item.type === $constants.text.type.checklist" :item="item" />
	<app-text-star v-if="item.type === $constants.text.type.star" :item="item" />
	<app-text-location v-if="item.type === $constants.text.type.location" :item="item" />
	<app-text-pin v-if="item.type === $constants.text.type.pin" :item="item" />
	<app-text-link v-if="item.type === $constants.text.type.link" :item="item" />
	<app-text-group v-if="item.type === $constants.text.type.group" :item="item" />
	<app-text-image v-if="item.type === $constants.text.type.image" :item="item" />
	<app-text-text v-if="item.type === $constants.text.type.text" :item="item" />
	<app-text-embed v-if="item.type === $constants.text.type.embed" :item="item" />
	<app-text-phone v-if="item.type === $constants.text.type.phone" :item="item" />
	<app-text-connectedgroups v-if="item.type === $constants.text.type.connectedgroups" :item="item" />

	<app-icon icon="insert" v-tooltip="'Insert'" class="text-insert is-first" v-on:click.native.stop.prevent="$emit('insert', index)" v-if="isFirst && !isPreview" />
	<app-icon icon="insert" v-tooltip="'Insert'" class="text-insert" v-on:click.native.stop.prevent="$emit('insert', index + 1)" v-if="!isPreview" />

</router-link>

</template>

<script>

export default {

	props: ['item', 'index', 'route', 'isFirst', 'isLast', 'isPreview']

}

</script>

<style scoped>

.text {
	border-bottom: 1px dashed #eee;
	border-top: 1px dashed #eee;
	border-left: 2px solid #eee;
	border-right: 2px solid #eee;
	padding: 0px 20px;
	display: block;
	cursor: pointer;
}

.text.is-first {
	border-top: 2px solid #eee;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}

.text.is-last {
	border-bottom: 2px solid #eee;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
}

.text:hover {
	border-color: #4082d3;
}

.text-insert {
	position: absolute;
	right: -8px;
	font-size: 20px;
	bottom: -10px;
	z-index: 2;
	color: #999;
	background-color: #fff;
	cursor: pointer;
}

.text-insert:hover {
	color: #4082d3;
}

.text-insert.is-first {
	top: -9px;
	bottom: auto;
}

.text.is-preview {
	pointer-events: none;
}

.text.is-preview >>> .text-block {
	margin: 20px 0px 20px 0px;
}

.text.is-first >>> .text-block {
	margin-top: 20px;
}

.text.is-last >>> .text-block:last-child {
	margin-bottom: 20px;
}

.text.is-last >>> .location,
.text.is-preview >>> .location {
	padding-bottom: 0px;
}

</style>
