<template>

<div class="link text-block">

	<div class="link-icon"></div>
	<h3 class="link-text"><a :href="item.url" targert="_blank">{{ item.content }}</a></h3>

</div>

</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.link {
	display: flex;
	flex-direction: row;
}

.link-icon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	flex-shrink: 0;
	background-size: 17px 17px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url(~@/assets/text/link.png);
}

.link-text {
	color: #707070;
	font-size: 18px;
	flex-grow: 1;
	line-height: 24px;
	margin-bottom: 5px;
}

</style>
