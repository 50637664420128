<template>

<app-input :validation="validation" :label="label" :stacked="stacked" :no-line="noLine" :notes="notes">

	<slot></slot>
	
</app-input>

</template>

<script>

export default {

	props: ['validation', 'label', 'noLine', 'stacked', 'notes']

}

</script>

<style scoped>

</style>
