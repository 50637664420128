import _ from 'underscore'
import Vue from 'vue'
import live from '@/live'
import api from '@/api'

export default {

	namespaced: true,

	state: {
		data: {
			access: []
		}
	},

	getters: {

		all: function(state) {

			return _.sortBy(state.data.access, function(item) {

				return item.user.name.toLowerCase()

			})

		},

		find: function(state) {

			return function(id) {

				return _.findWhere(state.data.access, {
					id: parseInt(id)
				})

			}

		}

	},

	mutations: {

		set: function(state, data) {

			state.data.access = data.access

		},

		update: function(state, data) {

			var index = _.findIndex(state.data.access, {
				id: parseInt(data.id)
			})

			if (index !== -1) {
				
				Vue.set(state.data.access, index, data)

			} else {

				state.data.access.push(data)

			}

		},

		order: function(state, data) {

			var index = _.findIndex(state.data.access, {
				id: parseInt(data.id)
			})

			Vue.set(state.data.access[index], 'order', data.order)

		},

		delete: function(state, data) {

			var index = _.findIndex(state.data.access, {
				id: parseInt(data.id)
			})

			state.data.access.splice(index, 1)

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)

			live.$on('access/add', function(params) {

				context.commit('update', params)

			})

			live.$on('access/update', function(params) {

				context.commit('update', params)

			})

			live.$on('access/delete', function(params) {

				context.commit('delete', params.id)

			})

		},

		save: function(context, data) {

			return (data.id) ? api.put('access/' + data.id, data) : api.post('access', data)

		},

		delete: function(context, data) {

			return api.delete('access/' + data.id, data)

		}

	}

}
