import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [

		{ name: 'Login', path: '/login', meta: { auth: false }, component: () => import('@/components/page/Login') },

		{ name: 'Dashboard', path: '/', meta: { auth: true }, component: () => import('@/components/page/Dashboard') },

		{ name: 'Embed', path: '/embed', meta: { auth: true }, component: () => import('@/components/page/Embed') },
		{ name: 'Share', path: '/share', meta: { auth: true }, component: () => import('@/components/page/Share') },
		{ name: 'Analytics', path: '/analytics', meta: { auth: true }, component: () => import('@/components/page/Analytics') },

		{ name: 'Requests', path: '/requests', meta: { auth: true }, component: () => import('@/components/page/Requests') },
		{ name: 'Request.Delete', path: '/requests/:request/delete', meta: { auth: true, parent: 'Requests', back: 'Request', stash: 'Request', noun: 'request', store: 'requests', param: 'request' }, component: () => import('@/components/page/Delete') },
		{ name: 'Request.Unsaved', path: '/requests/:request/unsaved', meta: { auth: true, parent: 'Requests', back: 'Request', stash: 'Request', noun: 'request', store: 'requests', param: 'request' }, component: () => import('@/components/page/Unsaved') },
		{ name: 'Request', path: '/requests/:request', meta: { auth: true, parent: 'Requests' }, component: () => import('@/components/page/Request') },
		
		{ name: 'Categories', path: '/categories', meta: { auth: true }, component: () => import('@/components/page/Categories') },
		{ name: 'Categories.Delete', path: '/categories/delete', meta: { auth: true, parent: 'Categories', back: 'Categories', noun: 'category', store: 'categories' }, component: () => import('@/components/page/Delete') },
		{ name: 'Category.Clone', path: '/categories/clone', meta: { auth: true, parent: 'Categories', back: 'Categories', noun: 'category', store: 'categories', param: 'category' }, component: () => import('@/components/page/Clone') },
		{ name: 'Category', path: '/categories/:category', meta: { auth: true, parent: 'Categories' }, component: () => import('@/components/page/Category') },
		{ name: 'Category.Delete', path: '/categories/:category/delete', meta: { auth: true, parent: 'Categories', back: 'Category', stash: 'Category', noun: 'category', store: 'categories', param: 'category' }, component: () => import('@/components/page/Delete') },
		{ name: 'Category.Unsaved', path: '/categories/:category/unsaved', meta: { auth: true, parent: 'Categories', back: 'Category', stash: 'Category', noun: 'category', store: 'categories', param: 'category' }, component: () => import('@/components/page/Unsaved') },
		{ name: 'Category.Groups', path: '/categories/:category/groups', meta: { auth: true, parent: 'Categories', byCategory: true }, component: () => import('@/components/page/Groups') },
		
		{ name: 'Groups', path: '/groups', meta: { auth: true }, component: () => import('@/components/page/Groups') },
		{ name: 'Groups.Delete', path: '/groups/delete', meta: { auth: true, parent: 'Groups', back: 'Groups', noun: 'group', store: 'groups' }, component: () => import('@/components/page/Delete') },
		{ name: 'Group.Clone', path: '/groups/clone', meta: { auth: true, parent: 'Groups', back: 'Groups', noun: 'group', store: 'groups', param: 'group' }, component: () => import('@/components/page/Clone') },
		{ name: 'Group', path: '/groups/:group', meta: { auth: true, parent: 'Groups' }, component: () => import('@/components/page/Group') },
		{ name: 'Group.Delete', path: '/groups/:group/delete', meta: { auth: true, parent: 'Groups', back: 'Group', stash: 'Group', noun: 'group', store: 'groups', param: 'group' }, component: () => import('@/components/page/Delete') },
		{ name: 'Group.Unsaved', path: '/groups/:group/unsaved', meta: { auth: true, parent: 'Groups', back: 'Group', stash: 'Group', noun: 'group', store: 'groups', param: 'group' }, component: () => import('@/components/page/Unsaved') },
		{ name: 'Group.Items', path: '/groups/:group/items', meta: { auth: true, parent: 'Groups', byGroup: true }, component: () => import('@/components/page/Items') },
		{ name: 'Group.Text', path: '/groups/:group/texts/:text', meta: { auth: true, stash: 'Group', parent: 'Groups', byGroup: true, back: 'Group' }, component: () => import('@/components/page/Text') },
		{ name: 'Group.Text.Unsaved', path: '/groups/:group/texts/:text/unsaved', meta:  { auth: true, parent: 'Texts', back: 'Group.Text', stash: 'Group.Text', noun: 'text', store: 'texts', param: 'text' }, component: () => import('@/components/page/Unsaved') },

		{ name: 'Items', path: '/items', meta: { auth: true }, component: () => import('@/components/page/Items') },
		{ name: 'Items.Delete', path: '/items/delete', meta: { auth: true, parent: 'Items', back: 'Items', noun: 'item', store: 'items' }, component: () => import('@/components/page/Delete') },
		{ name: 'Item.Clone', path: '/items/clone', meta: { auth: true, parent: 'Items', back: 'Items', noun: 'item', store: 'items', param: 'item' }, component: () => import('@/components/page/Clone') },
		{ name: 'Item.Delete', path: '/items/:item/delete', meta: { auth: true, parent: 'Items', back: 'Item', stash: 'Item', noun: 'item', store: 'items', param: 'item' }, component: () => import('@/components/page/Delete') },
		{ name: 'Item.Unsaved', path: '/items/:item/unsaved', meta: { auth: true, parent: 'Items', back: 'Item', stash: 'Item', noun: 'item', store: 'items', param: 'item' }, component: () => import('@/components/page/Unsaved') },
		{ name: 'Item', path: '/items/:item', meta: { auth: true, parent: 'Items' }, component: () => import('@/components/page/Item') },

		{ name: 'Texts', path: '/texts', meta: { auth: true }, component: () => import('@/components/page/Texts') },
		{ name: 'Texts.Delete', path: '/texts/delete', meta: { auth: true, parent: 'Texts', back: 'Texts', noun: 'text', store: 'texts' }, component: () => import('@/components/page/Delete') },
		{ name: 'Text', path: '/texts/:text', meta: { auth: true, parent: 'Texts', back: 'Texts' }, component: () => import('@/components/page/Text') },
		{ name: 'Text.Unsaved', path: '/texts/:text/unsaved', meta:  { auth: true, parent: 'Texts', back: 'Text', stash: 'Text', noun: 'text', store: 'texts', param: 'text' }, component: () => import('@/components/page/Unsaved') },

		{ name: 'Locations', path: '/locations', meta: { auth: true }, component: () => import('@/components/page/Locations') },
		{ name: 'Locations.Delete', path: '/locations/delete', meta: { auth: true, parent: 'Locations', back: 'Locations', noun: 'location', store: 'locations' }, component: () => import('@/components/page/Delete') },
		{ name: 'Location.Clone', path: '/locations/clone', meta: { auth: true, parent: 'Locations', back: 'Locations', noun: 'location', store: 'locations', param: 'location' }, component: () => import('@/components/page/Clone') },
		{ name: 'Location', path: '/locations/:location', meta: { auth: true, parent: 'Locations' }, component: () => import('@/components/page/Location') },
		{ name: 'Location.Delete', path: '/locations/:location/delete', meta: { auth: true, parent: 'Locations', back: 'Location', stash: 'Location', noun: 'location', store: 'locations', param: 'location' }, component: () => import('@/components/page/Delete') },
		{ name: 'Location.Unsaved', path: '/locations/:location/unsaved', meta: { auth: true, parent: 'Locations', back: 'Location', stash: 'Location', noun: 'location', store: 'locations', param: 'location' }, component: () => import('@/components/page/Unsaved') },
		{ name: 'Location.Text', path: '/locations/:location/texts/:text', meta: { auth: true, stash: 'Location', parent: 'Locations', byLocation: true, back: 'Location' }, component: () => import('@/components/page/Text') },
		{ name: 'Location.Text.Unsaved', path: '/locations/:location/texts/:text/unsaved', meta:  { auth: true, parent: 'Locations', back: 'Location.Text', stash: 'Location.Text', noun: 'text', store: 'texts', param: 'text' }, component: () => import('@/components/page/Unsaved') },
		{ name: 'Location.Groups', path: '/locations/:location/groups', meta: { auth: true, parent: 'Locations', byLocation: true }, component: () => import('@/components/page/Groups') },
		
		{ name: 'Terms', path: '/terms', meta: { auth: true }, component: () => import('@/components/page/Terms') },
		{ name: 'Term.New', path: '/terms/new', meta: { auth: true, parent: 'Terms', back: 'Terms' }, component: () => import('@/components/page/Term') },
		{ name: 'Term', path: '/terms/:type/:parent/:term', meta: { auth: true, parent: 'Terms', back: 'Terms' }, component: () => import('@/components/page/Term') },

		{ name: 'Accesses', path: '/access', meta: { auth: true }, component: () => import('@/components/page/Accesses') },
		{ name: 'Access.Delete', path: '/access/:access/delete', meta: { auth: true, parent: 'Accesses', back: 'Access', stash: 'Access', noun: 'access', store: 'access', param: 'access' }, component: () => import('@/components/page/Delete') },
		{ name: 'Access.Unsaved', path: '/access/:access/unsaved', meta: { auth: true, parent: 'Accesses', back: 'Access', stash: 'Access', noun: 'access', store: 'access', param: 'access' }, component: () => import('@/components/page/Unsaved') },
		{ name: 'Access', path: '/access/:access', meta: { auth: true, parent: 'Accesses' }, component: () => import('@/components/page/Access') },

		{ name: 'Settings', path: '/settings', meta: { auth: true }, component: () => import('@/components/page/Settings') },
		{ name: 'Logout', path: '/logout', meta: { auth: true }, component: () => import('@/components/page/Logout') }
		
	]
})