export default {

	namespaced: true,

	state: {
		data: {
			key: '',
			name: '',
			email: '',
			guides: 0
		}
	},

	getters: {

		get: function(state) {

			return state.data

		},

		key: function(state) {

			return state.data.key

		}

	},

	mutations: {

		set: function(state, data) {

			state.data = data.user

		},

		update: function(state, data) {

			if (state.data.key === data.key) {

				state.data = data

			}

		},

	},	

	actions: {

		init: function(context, data) {

			context.commit('set', data)

		}

	}	

}
