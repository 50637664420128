<template>
	
<router-link :to="{name: route, params: params}" class="badge" :class="{'is-nothing': !route, 'is-excluded': excluded}" v-bgimage="image" v-bgcolor="color" v-tooltip="tooltip">

	<app-icon icon="noimage" class="badge-missing" v-if="!image && !icon" />
	<app-icon :icon="icon" class="badge-missing" v-if="!image && icon" />

	<app-icon icon="notice" class="badge-flag" v-if="flag" />

	<app-icon v-tooltip="'Hidden'" icon="invisible" class="badge-invisible" v-if="invisible" />

	<app-icon v-tooltip="'Not this'" icon="excluded" class="badge-excluded" v-if="excluded" />

</router-link>

</template>

<script>

export default {

	props: ['route', 'flag', 'params', 'excluded', 'icon', 'tooltip', 'image', 'color', 'invisible']

}

</script>

<style scoped>

.badge {
	width: 32px;
	height: 32px;
	border-radius: 4px;
	flex-shrink: 0;
	background-size: 16px auto;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	margin-right: 10px;
	background-color: #eee;
}

.badge.is-excluded {
	background-color: #FFECED;
}

.badge.is-nothing {
	pointer-events: none;
}

.badge-invisible {
	color: #fff;
	font-size: 10px;
	position: absolute;
	width: 16px;
	height: 16px;
	line-height: 19px;
	text-align: center;
	right: -5px;
	top: -5px;
	border-radius: 50%;
	background-color: #dbc5c5;
}

.badge-excluded {
	color: #FB6178;
	font-size: 8px;
	position: absolute;
	left: 2px;
	top: 2px;
}

.badge-missing {
	font-size: 16px;
	color: #999;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.badge-flag {
	position: absolute;
	right: -6px;
	top: -6px;
	font-size: 16px;
	color: #c55b5b;
}

</style>
