<template>

<div class="group text-block">

	<div class="group-image" v-bgimage="group.image">

		<app-icon icon="noimage" class="group-image-missing" v-if="!group.image" />

	</div>

	<div class="group-text">
			
		<h3>{{ group.title }} <small>({{ count }})</small></h3>
			
	</div>

</div>

</template>

<script>

export default {

	props: ['item'],

	computed: {

		group: function() {

			return (this.item.group) ? this.$store.getters['groups/find'](this.item.group) : {
				title: 'Sample text'
			}

		},

		count: function() {

			return (this.item.group) ? this.$store.getters['items/find/group'](this.item.group).length : 0

		}

	}

}

</script>

<style scoped>

.group {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 20px;
}

.group-image {
	width: 40px;
	height: 40px;
	margin-right: 10px;
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	flex-shrink: 0;
}

.group-text {
	flex-grow: 1;
}

.group-text h3 {
	color: #707070;
	font-size: 22px;
	flex-grow: 1;
}

.group-text h3 small {
	font-size: 16px;
}

.group-image-missing {
	font-size: 18px;
	color: #999;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

</style>
