export default {

	previewTypes: {
		1: 'Fluid',
		2: 'Mock Mobile',
	},

	locationServices: {
		1: 'Give / Donate',
		2: 'Recycle',
		3: 'Sell',
		4: 'Trade In'
	},

	status: {
		loading: 1,
		success: 2,
		failed: 3
	},

	type: {
		category: 1,
		group: 2,
		item: 3,
		location: 4
	},

	typeName: {
		1: 'Category',
		2: 'Group',
		3: 'Item',
		4: 'Location'
	},

	text: {
		setup: {
			use: 1,
			copy: 2,
			blank: 3
		},
		type: {
			title: 1,
			tick: 2,
			cross: 3,
			check: 4,
			star: 5,
			location: 6,
			pin: 7,
			link: 8,
			group: 9,
			image: 10,
			text: 11,
			embed: 12,
			phone: 13,
			checklist: 14,
			connectedgroups: 15,
		},
		icon: {
			1: 'text.type.1',
			2: 'text.type.2',
			3: 'text.type.3',
			4: 'text.type.4',
			5: 'text.type.5',
			6: 'text.type.6',
			7: 'text.type.7',
			8: 'text.type.8',
			9: 'text.type.9',
			10: 'text.type.10',
			11: 'text.type.11',
			12: 'text.type.12',
			13: 'text.type.13',
			14: 'text.type.14',
			15: 'text.type.15'
		}
	},

	icons: [
		{
			value: 'img/icons/apple.php',
			name: 'Apple'
		},
		{
			value: 'img/icons/autobattery.php',
			name: 'Auto-battery'
		},
		{
			value: 'img/icons/barrel.php',
			name: 'Barrel'
		},
		{
			value: 'img/icons/battery.php',
			name: 'Battery'
		},
		{
			value: 'img/icons/bottle.php',
			name: 'Bottle'
		},
		{
			value: 'img/icons/clothing.php',
			name: 'Clothing'
		},
		{
			value: 'img/icons/dumpster.php',
			name: 'Dumpster'
		},
		{
			value: 'img/icons/excavation.php',
			name: 'Excavation'
		},
		{
			value: 'img/icons/fuse.php',
			name: 'Fuse'
		},
		{
			value: 'img/icons/hazard.php',
			name: 'Hazard'
		},
		{
			value: 'img/icons/heart.php',
			name: 'Heart'
		},
		{
			value: 'img/icons/home.php',
			name: 'Home'
		},
		{
			value: 'img/icons/info.php',
			name: 'Info'
		},
		{
			value: 'img/icons/leaf.php',
			name: 'Leaf'
		},
		{
			value: 'img/icons/location.php',
			name: 'Location'
		},
		{
			value: 'img/icons/marketplace.php',
			name: 'Market Place'
		},
		{
			value: 'img/icons/nut.php',
			name: 'Nut'
		},
		{
			value: 'img/icons/oil.php',
			name: 'Oil'
		},
		{
			value: 'img/icons/market.php',
			name: 'Online Market Place'
		},
		{
			value: 'img/icons/package.php',
			name: 'Package'
		},
		{
			value: 'img/icons/packaging.php',
			name: 'Packaging'
		},
		{
			value: 'img/icons/recycle.php',
			name: 'Recycle'
		},
		{
			value: 'img/icons/return.php',
			name: 'Return'
		},
		{
			value: 'img/icons/star.php',
			name: 'Star'
		},
		{
			value: 'img/icons/thumb.php',
			name: 'Thumb'
		},
		{
			value: 'img/icons/tire.php',
			name: 'Tire'
		},
		{
			value: 'img/icons/trash.php',
			name: 'Trash'
		},
		{
			value: 'img/icons/truck.php',
			name: 'Truck'
		}
	]

}