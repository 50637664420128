<template>

<app-input :validation="validation" :label="label" :stacked="stacked" :no-line="noLine" :is-ready="value" :notes="notes" :disabled="disabled">

	<div class="banner" :class="{'is-imageless': !value.image}">

		<div key="x" v-bgimage="value.image" v-if="value.image && !value.type" class="banner-background" :style="{backgroundColor: backgroundColor}" />
		<img :src="imageSource" v-if="value.image && value.type" class="banner-image" />

		<template v-if="!value.image">No image uploaded.</template>

		<div class="banner-text" v-if="value.image && !value.type">

			<draggable handle=".text-move" v-model="value.texts" v-on:change="onTextSorted">

				<com-text v-for="(text, index) in value.texts" :key="index" :item="text" v-on:changed="onTextChanged(index, $event)" v-on:deleted="onTextDeleted(index)" />

			</draggable>

			<com-text :item="defaultText" :is-new="true" v-on:changed="onTextCreated" />

		</div>

		<div key="y" class="banner-button is-select" v-tooltip="(value.image) ? 'Change image' : 'Select image'" v-on:click="onSelectClick">

			<app-icon icon="upload" />

		</div>

		<div v-if="value.image" class="banner-button is-remove" v-tooltip="'Remove image'" v-on:click="onRemoveClick">

			<app-icon icon="delete" />

		</div>

	</div>

	<div class="banner-options" v-if="value.image">

		<div class="banner-options-type">

			<div class="banner-options-type-icon" :class="{'is-active': value.type}" v-tooltip="'Click to toggle'" v-on:click="onTypeClick"><app-icon icon="tick" /></div>
			<div class="banner-options-type-text">Static image only</div>

		</div>

		<div class="banner-options-color" v-if="!value.type">

			<input class="banner-options-color-input" :maxlength="7" v-model="color" placeholder="Background color" />
			<div class="banner-options-color-preview" :style="{backgroundColor: backgroundColor}" />

		</div>

	</div>

	<input ref="file" type="file" accept=".png,.jpg,.jpeg" v-on:change="onFileSelected" class="banner-file" />

</app-input>

</template>

<script>

import comText from './banner/Text'
import draggable from 'vuedraggable'

export default {

	props: ['validation', 'label', 'disabled', 'value', 'noLine', 'stacked', 'placeholder', 'notes'],

	components: {
		draggable,
		'com-text': comText
	},

	data: function() {

		return {
			color: '',
			defaultText: {
				text: 'Type text and press enter to confirm'
			}
		}

	},

	watch: {

		color: function(n) {

			var value = this.$util.clone(this.value)

			value.background = n.replace('#', '')

			this.$emit('input', value)

		}

	},

	computed: {

		imageSource: function() {

			return (this.value.image.indexOf('data:') === 0 || this.value.image.indexOf('https:') === 0) ? this.value.image : this.$store.getters['config'].base + this.value.image

		},

		backgroundColor: function() {

			return (this.value.background) ? '#' + this.value.background : '#ffffff'

		}

	},

	methods: {

		onRemoveClick: function() {

			this.$emit('input', {
				texts: [],
				image: '',
				type: 0
			})
				
		},

		onTypeClick: function() {

			var value = this.$util.clone(this.value)

			value.type = (value.type) ? 0 : 1

			this.$emit('input', value)

		},

		onTextChanged: function(index, data) {

			var value = this.$util.clone(this.value)

			value.texts[index] = this.$util.clone(data)

			this.$emit('input', value)

		},

		onTextSorted: function() {

			this.$emit('input', this.$util.clone(this.value))

		},

		onTextDeleted: function(index) {

			var value = this.$util.clone(this.value)

			value.texts.splice(index, 1)

			this.$emit('input', value)

		},

		onTextCreated: function(item) {

			var value = this.$util.clone(this.value)

			value.texts.push(item)

			this.$emit('input', value)

		},

		onSelectClick: function() {

			this.$refs.file.click()
				
		},

		onFileSelected: function(e) {

			var file = e.target.files[0]

			var reader = new FileReader()

			reader.onloadend = function() {

				var value = this.$util.clone(this.value)

				value.image = reader.result

				this.$emit('input', value)

			}.bind(this)

			reader.readAsDataURL(file)
			
		}

	}

}

</script>

<style scoped>

.banner {
    width: 100%;
    border-radius: 12px;
}

.banner.is-imageless {
	min-height: 40px;
	height: 40px;
	padding: 0px 10px;
	font-size: 16px;
	color: #333;
	line-height: 36px;
    border: 2px solid #eee;
}

.banner-image {
	width: calc(100% - 4px);
	height: auto;
    border-radius: 12px 12px 0px 0px;
	border-bottom-width: 0px;
    border: 2px solid #eee;
	display: block;
}

.banner-background {
	position: absolute;
	left: 0px;
	top: 0px;
    border: 2px solid #eee;
	border-bottom-width: 0px;
	right: 0px;
    border-radius: 12px 12px 0px 0px;
	bottom: 0px;
	z-index: 1;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-size: auto 160px;
}

.banner-text {
	padding: 12px 8px 12px 8px;
	z-index: 2;
	min-height: 160px;
}

.banner-button {
	flex-shrink: 0;
	border: 2px solid #eee;
	border-radius: 0px 12px 12px 0px;
	border-left-width: 0px;
	background-color: #fff;
	width: 36px;
	line-height: 36px;
	text-align: center;
	color: #4082d3;
	cursor: pointer;
	font-size: 16px;
	transition: all 100ms linear;
	background-color: #4082d3;
	border-color: #4082d3;
	color: #fff;
	position: absolute;
	right: -2px;
	z-index: 2;
	top: -2px;
}

.banner:not(.is-imageless) .banner-button {
	border-radius: 50%;
	width: 36px;
	height: 36px;
	border-width: 0px;
	line-height: 36px;
	right: 4px;
	top: auto;
	bottom: 4px;
}

.banner:not(.is-imageless) .banner-button.is-select {
	right: 44px;
}

.banner:not(.is-imageless) .banner-button.is-remove {
	right: 4px;
	background-color: #c94141;
}

.banner-file {
	display: none;
}

.banner-options {
	border: 2px solid #eee;
	border-top-width: 0px;
	border-radius: 0px 0px 12px 12px;
	display: flex;
	padding: 5px;
	justify-content: space-between;
}

.banner-options-type {
	display: flex;
}

.banner-options-type-icon {
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 20px;
	text-align: center;
	flex-shrink: 0;
	border-radius: 50%;
	background-color: #eee;
	color: #ccc;
	cursor: pointer;
}

.banner-options-type-icon:hover {
	background-color: #ccc;
	color: #999;
}

.banner-options-type-icon.is-active {
	background-color: #4082d3;
	color: #fff;
}

.banner-options-type-icon.is-active:hover {
	background-color: #4082d3;
	color: #fff;
}

.banner-options-type-text {
	font-size: 12px;
	color: #333;
	line-height: 20px;
	margin-left: 5px;
}

.banner-options-color {
	display: flex;
}

.banner-options-color-input {
	width: 92px;
	font-size: 12px;
	color: #333;
	text-align: right;
	line-height: 20px;
	height: 20px;
}

.banner-options-color-preview {
	width: 20px;
	height: 20px;
	margin-left: 5px;
	border-radius: 50%;
	border: 2px solid #eee;
}

</style>
