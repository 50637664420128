<template>

<div class="image text-block" v-bgimage="item.image">

	<app-icon icon="noimage" class="image-missing" v-if="!item.image" />

</div>

</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.image {
	width: 100%;
	height: auto;
	min-height: 200px;
	border-radius: 12px;
	background-color: #e7e7e7;
	background-position: 50% 50%;
	background-size: cover;
}

.image-missing {
	font-size: 64px;
	color: #999;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

</style>
