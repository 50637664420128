import store from '@/store'
import cookies from 'js-cookie'

export default function handler(to, from, next) {

	if (to.meta.auth && !store.getters['key'] && cookies.get('key') === undefined) {

		next({
			name: 'Login',
			query: {
				redirect: to.path
			}
		})

	} else {

		next()

	}

}