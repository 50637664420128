<template>

<div class="content" :class="{'is-padded': isPadded, 'is-grown': isGrown, 'is-loading': isLoading}">

	<com-drag v-if="canReorder" v-model="order" :forceFallback="true" handle=".drag-handle" v-on:update="$emit('reordered', order)">

		<slot></slot>

	</com-drag>

	<template v-if="!canReorder">

		<slot></slot>

	</template>

	<app-icon icon="loading" class="content-loading" v-if="isLoading" />

	<div class="content-empty" v-if="isEmpty">{{ emptyMessage }}</div>

</div>

</template>

<script>

export default {

	props: ['isPadded', 'canReorder', 'isGrown', 'isLoading', 'isEmpty', 'emptyMessage'],

	components: {
		'com-drag': () => import('vuedraggable')
	},

	data: function() {

		return {
			order: []
		}

	},

	created: function() {

		this.populateOrder()

	},

	watch: {

		canReorder: function() {

			this.populateOrder()

		}

	},

	methods: {

		populateOrder: function() {

			this.order = this.$_.clone(this.canReorder)

		}

	}	

}

</script>

<style scoped>

.content {
	flex-grow: 1;
	z-index: 1;
	padding: 20px 0px 20px 0px;
	overflow-y: auto;
	display: flex;
    flex-direction: column;
}

.content.is-loading > *:not(.content-loading) {
	opacity: 0.5;
}

.content.is-padded {
	padding: 20px;
}

.content.is-grown {
	padding-bottom: 0px;
}

.content-empty {
	font-size: 24px;
	color: #ccc;
	font-weight: 400;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.content-loading {
	font-size: 96px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	color: #4082d3;
}

</style>
