import _ from 'underscore'
import Vue from 'vue'
import live from '@/live'
import api from '@/api'

export default {

	namespaced: true,

	state: {
		data: {
			items: []
		}
	},

	getters: {

		all: function(state) {

			return _.sortBy(state.data.items, function(item) {

				return item.title.toLowerCase()

			})

		},

		find: function(state) {

			return function(id) {

				return _.findWhere(state.data.items, {
					id: parseInt(id)
				})

			}

		},

		'find/group': function(state) {

			return function(id) {

				return _.where(state.data.items, {
					group: parseInt(id)
				})

			}

		}

	},

	mutations: {

		set: function(state, data) {

			state.data.items = data.items

		},

		update: function(state, data) {

			var index = _.findIndex(state.data.items, {
				id: data.id
			})

			if (index !== -1) {
				
				Vue.set(state.data.items, index, data)

			} else {

				state.data.items.push(data)

			}

		},

		order: function(state, data) {

			var index = _.findIndex(state.data.items, {
				id: data.id
			})

			Vue.set(state.data.items[index], 'order', data.order)

		},

		delete: function(state, data) {

			var index = _.findIndex(state.data.items, {
				id: data.id
			})

			state.data.items.splice(index, 1)

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)

			live.$on('item/add', function(params) {

				context.commit('update', params)

			})

			live.$on('item/update', function(params) {

				context.commit('update', params)

			})

			live.$on('item/delete', function(params) {

				context.commit('delete', params.id)

			})

		},

		save: function(context, data) {

			return (data.id) ? api.put('item/' + data.id, data) : api.post('item', data)

		},

		delete: function(context, data) {

			return api.delete('item/' + data.id, data)

		},

		reorder: async function(context, data) {

			_.each(data, function(id, index) {

				context.commit('order', {
					id: id,
					order: index + 1
				})

			})

			await api.post('items/order', {
				order: data
			})

		}

	}

}
