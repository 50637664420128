<template>

<div class="phone text-block">

	<div class="phone-icon"></div>
	<h3 class="phone-text" v-html="$nl2br(item.content)"></h3>

</div>

</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.phone {
	display: flex;
	flex-direction: row;
}

.phone-icon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	flex-shrink: 0;
	background-size: 20px 20px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url(~@/assets/text/phone.png);
}

.phone-text {
	color: #707070;
	font-size: 18px;
	flex-grow: 1;
	line-height: 24px;
	margin-bottom: 5px;
}

</style>
