import _ from 'underscore'
import Vue from 'vue'
import api from '@/api'
import live from '@/live'

export default {

	namespaced: true,

	state: {
		data: {
			categories: []
		}
	},

	getters: {

		all: function(state) {

			return _.sortBy(state.data.categories, function(item) {

				return item.order

			})

		},

		find: function(state) {

			return function(id) {

				return _.findWhere(state.data.categories, {
					id: parseInt(id)
				})

			}

		}

	},

	mutations: {

		set: function(state, data) {

			state.data.categories = data.categories

			state.data.categories.push({
				id: 0,
				background: '0b234e',
				visible: false,
				icon: 'uncategorized',
				title: 'Library',
				order: 1000
			})

		},

		update: function(state, data) {

			var index = _.findIndex(state.data.categories, {
				id: data.id
			})

			if (index !== -1) {
				
				Vue.set(state.data.categories, index, data)

			} else {

				state.data.categories.push(data)

			}

		},

		order: function(state, data) {

			var index = _.findIndex(state.data.categories, {
				id: data.id
			})

			Vue.set(state.data.categories[index], 'order', data.order)

		},

		delete: function(state, data) {

			var index = _.findIndex(state.data.categories, {
				id: data.id
			})

			state.data.categories.splice(index, 1)

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)

			live.$on('category/add', function(params) {

				context.commit('update', params)

			})

			live.$on('category/update', function(params) {

				context.commit('update', params)

			})

			live.$on('category/delete', function(params) {

				context.commit('delete', params.id)

			})

		},


		save: function(context, data) {

			return (data.id) ? api.put('category/' + data.id, data) : api.post('category', data)

		},

		delete: function(context, data) {

			return api.delete('category/' + data.id, data)

		},

		reorder: async function(context, data) {

			_.each(data, function(id, index) {

				context.commit('order', {
					id: id,
					order: index + 1
				})

			})

			await api.post('categories/order', {
				order: data
			})

		}

	}

}
