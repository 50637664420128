<template>
	
<div class="drag drag-handle" :class="{'is-disabled': disabled}" v-tooltip="'Drag and drop to re-order'">

	<i class="fa fa-sort"></i>

</div>

</template>

<script>

export default {

	props: ['disabled']

}

</script>

<style scoped>

.drag {
	width: 24px;
	min-width: 24px;
	height: 32px;
	margin-right: 20px;
	display: flex;
	justify-content: center;
	cursor: grab;
	align-items: center;
	color: #ccc;
	font-size: 24px;
	flex-shrink: 0;
	flex-direction: column;
}

.drag.is-disabled {
	pointer-events: none;
	visibility: hidden;
}

.is-desktop .drag:hover {
	color: #333;
}

</style>
