<template>

<div class="text" :class="{'is-new': isNew, 'is-editing': is.editing}">

	<div class="text-wrapper">

		<div class="text-icon" :data-type="icon" v-tooltip="'Click to toggle icon'" v-on:click="onIconClick" v-if="!isNew"></div>

		<div class="text-edit"><span contenteditable v-on:keydown.enter="onEnter" v-on:focus="onFocus" v-on:blur="onBlur" v-tooltip="'Click to edit text'" v-html="item.text"></span></div>

		<div class="text-delete" v-tooltip="'Click to remove'" v-on:click="onDeleteClick" v-if="!isNew"><app-icon icon="delete" /></div>

		<div class="text-move" v-tooltip="'Drag to re-order'" v-if="!isNew"><app-icon icon="reorder" /></div>

	</div>

</div>

</template>

<script>

export default {

	props: ['item', 'isNew'],

	data: function() {

		return {
			is: {
				editing: false
			},
			icon: 'tick',
			iconList: ['tick', 'cross', 'bullet']
		}

	},

	created: function() {

		this.icon = this.item.icon || this.icon

	},

	watch: {

		item: {

			deep: true,

			handler: function() {

				this.icon = this.item.icon 

			}

		}

	},

	methods: {

		onDeleteClick: function() {

			this.$emit('deleted')

		},

		onIconClick: function() {

			var index = this.iconList.indexOf(this.icon)

			index = (this.iconList.length === index + 1) ? 0 : index + 1

			this.icon = this.iconList[index]

			var item = this.$util.clone(this.item)

			item.icon = this.icon

			this.$emit('changed', item)

		},

		onFocus: function(e) {

			this.is.editing = true

			if (this.isNew) {

				var selection = window.getSelection();
				var range = document.createRange();
				range.selectNodeContents(e.target);
				selection.removeAllRanges();
				selection.addRange(range);

			}

		},

		onBlur: function(e) {

			this.is.editing = false

			if (this.isNew) {

				e.target.innerHTML = this.item.text

			} else {

				var item = this.$util.clone(this.item)

				item.text = e.target.innerHTML

				this.$emit('changed', item)
				
			}

		},

		onEnter: function(e) {

			if (!e.shiftKey) {

				e.preventDefault()

				if (e.target.innerHTML) {

					var item = this.$util.clone(this.item)

					item.text = e.target.innerHTML
					item.icon = this.icon

					this.$emit('changed', item)
					
				}

				if (this.isNew) {

					e.target.innerHTML = this.item.text
						
				}

				e.target.blur()
					
			}

		}

	}

}

</script>

<style scoped>

.text {
	margin-bottom: 8px;
	user-select: none;
}

.text-wrapper {
	display: inline-flex;
	max-width: 80%;
}

.text-move {
	position: absolute;
	right: -40px;
	top: 0px;
	width: 20px;
	height: 20px;
	background-color: #4082d3;
	color: #fff;
	line-height: 20px;
	font-size: 12px;
	text-align: center;
	cursor: move;
	cursor: -webkit-grabbing;
	z-index: 1;
}

.text-delete {
	position: absolute;
	right: -20px;
	top: 0px;
	width: 20px;
	font-size: 12px;
	height: 20px;
	background-color: #f25151;
	color: #fff;
	line-height: 20px;
	text-align: center;
	cursor: pointer;
	z-index: 1;
}

.text-icon {
	position: absolute;
	top: 0px;
	height: 20px;
	left: 0px;
	width: 26px;
	background-color: #fff;
	flex-shrink: 0;
	z-index: 3;
	background-size: 13px 13px;
	background-position: 50% 3.5px;
	background-repeat: no-repeat;
	cursor: pointer;
}

.text-icon[data-type="tick"] {
	background-image: url(~@/assets/text/tick.png);
}

.text-icon[data-type="cross"] {
	background-image: url(~@/assets/text/cross.png);
}

.text-icon[data-type="check"] {
	background-image: url(~@/assets/text/check.png);
}

.text-icon[data-type="star"] {
	background-image: url(~@/assets/text/star.png);
}

.text-icon[data-type="bullet"] {
	background-size: 14px 14px;
	background-position: 50% 2.5px;
	background-image: url(~@/assets/text/bullet.png);
}

.text-edit {
	display: inline-block;
	color: #333;
	z-index: 2;
	padding-left: 26px;
	overflow: hidden;
}

.text-edit >>> span {
	padding: 2px 5px 2px 2px;
    line-height: 20px;
    font-size: 14px;
	background-color: #fff;
	-webkit-box-decoration-break: clone;
	box-decoration-break: clone;
}

.text.is-new .text-edit {
	padding-left: 5px;
}

.text.is-new:not(.is-editing) .text-edit {
	color: #999;
	font-style: italic;
}

</style>
