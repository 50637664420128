<template>

<h5 class="title text-block" :class="{'is-first': isFirst}">

	{{ item.content }}

</h5>

</template>

<script>

export default {

	props: ['item', 'isFirst']

}

</script>

<style scoped>

.title {
	color: #707070;
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-top: 30px;
	margin-bottom: 10px;
	font-weight: 300;
	font-family: Roboto Slab,serif;
}

</style>
