import slugify from 'slugify'
import _ from 'underscore'

export default {

	stringAsNumber: function(text) {

		var value = '1'
		var alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']

		for (var i = 1; i <= 10; i++) {

			if (text.length >= i) {

				if (_.contains(alphabet, text[i - 1].toLowerCase())) {

					value += alphabet.indexOf(text[i - 1].toLowerCase()).toString().padStart(2, '0')

				}  else {

					value += '00'

				}

			} else {

				value += '00'

			}

		}

		return parseInt(value)

	},
	
	clone: function(a) {

		return JSON.parse(JSON.stringify(a))

	},

	search: function(text, match) {

		text = slugify(text, {
			replacement: ' ',
			lower: true,
			remove: /[*+~.()'"!:@]/g,
			strict: true
		})

		match = slugify(match, {
			replacement: ' ',
			lower: true,
			remove: /[*+~.()'"!:@]/g,
			strict: true
		})

		return text.indexOf(match) >= 0

	}

}