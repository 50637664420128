<template>

<app-input class="icons" :validation="validation" :label="label" :stacked="stacked" :no-line="noLine" :notes="notes" :disabled="disabled">

	<div class="icons-item" v-for="(option, key) in options" :key="key" :class="{'is-active': value === option.value}" v-on:click="$emit('input', option.value)" v-bgcolor="(value === option.value) ? bgcolor : 'eee'" v-tooltip="option.name" v-bgimage="option.value + '?fill=' + ((value === option.value) ? 'ffffff' : bgcolor)" />

</app-input>

</template>

<script>

export default {

	props: ['validation', 'label', 'disabled', 'options', 'value', 'stacked', 'placeholder', 'noLine', 'notes', 'bgcolor']

}

</script>

<style scoped>

.icons >>> .input-content {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.icons-item {
	width: 40px;
	height: 40px;
	border-radius: 4px;
	margin-right: 4px;
	margin-bottom: 4px;
	background-color: #fff;
	background-position: 50% 50%;
	background-size: 20px auto;
	background-repeat: no-repeat;
	background-color: #eee;
	cursor: pointer;
	transition: all 100ms linear;
}

.icons-item:not(.is-active):hover {
	background-color: #ccc;
}

.icons-item.is-active {
	background-color: #4082d3;
}

</style>
