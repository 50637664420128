<template>

<app-input class="texts" :label="label" :stacked="stacked" :no-line="noLine" :notes="notes" :disabled="disabled" :validation="validation">

	<div class="texts-wrapper">

		<div class="texts-list" :class="{'is-empty': !texts.length}">
			
			<template v-if="!value.length">No content blocks added.</template>

			<com-drag v-model="order" :forceFallback="true" handle=".drag-handle" v-on:update="onReordered">

				<template v-for="(text, index) in texts">

					<app-text :key="index" :route="route" :index="index" :is-first="index === 0" :is-last="index === texts.length - 1" :item="text" v-on:insert="onInsertClick" />

				</template>

			</com-drag>

		</div>
	
		<router-link :to="{name: route, params: { text: 'new' }}" class="texts-button" v-if="!texts.length" v-tooltip="'Add'">

			<app-icon icon="insert" />

		</router-link>

	</div>

</app-input>

</template>

<script>

export default {

	props: ['validation', 'label', 'disabled', 'route', 'value', 'store', 'stacked', 'placeholder', 'noLine', 'notes'],

	components: {
		'com-drag': () => import('vuedraggable')
	},

	data: function() {

		return {
			order: []
		}

	},

	created: function() {

		this.populateOrder()

	},

	watch: {

		value: function() {

			this.populateOrder()

		}

	},

	computed: {

		texts: function() {

			return this.$store.getters['texts/find/index'](this.value)

		}

	},

	methods: {

		populateOrder: function() {

			this.order = this.$_.clone(this.value)

		},

		onReordered: function() {

			this.$emit('input', this.order)

		},

		onInsertClick: function(index) {

			this.$router.push({
				name: this.route, 
				params: { 
					text: 'new' 
				},
				query: {
					position: index
				}
			})

		}

	}

}

</script>

<style scoped>

.texts-wrapper {
	display: flex;
}

.texts-list {
	border-radius: 12px;
	min-height: 40px;
	flex-grow: 1;
}

.texts-list.is-empty {
	line-height: 36px;
	padding: 0px 10px;
	font-size: 16px;
	color: #666;
	border: 2px solid #eee;
	border-radius: 12px 0px 0px 12px;
	border-right-width: 0px;
}

.texts-button {
	flex-shrink: 0;
	border: 2px solid #eee;
	border-radius: 0px 12px 12px 0px;
	border-left-width: 0px;
	background-color: #fff;
	width: 36px;
	line-height: 36px;
	text-align: center;
	color: #4082d3;
	cursor: pointer;
	font-size: 16px;
	transition: all 100ms linear;
	background-color: #4082d3;
	border-color: #4082d3;
	color: #fff;
}

</style>
