import _ from 'underscore'
import Vue from 'vue'
import live from '@/live'
import api from '@/api'

export default {

	namespaced: true,

	state: {
		data: {
			locations: []
		}
	},

	getters: {

		all: function(state) {

			return _.sortBy(state.data.locations, function(item) {

				return item.title.toLowerCase()

			})

		},

		find: function(state) {

			return function(id) {

				return _.findWhere(state.data.locations, {
					id: parseInt(id)
				})

			}

		}

	},

	mutations: {

		set: function(state, data) {

			state.data.locations = data.locations

		},

		update: function(state, data) {

			var index = _.findIndex(state.data.locations, {
				id: data.id
			})

			if (index !== -1) {
				
				Vue.set(state.data.locations, index, data)

			} else {

				state.data.locations.push(data)

			}

		},

		delete: function(state, data) {

			var index = _.findIndex(state.data.locations, {
				id: data.id
			})

			state.data.locations.splice(index, 1)

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)

			live.$on('location/add', function(params) {

				context.commit('update', params)

			})

			live.$on('location/update', function(params) {

				context.commit('update', params)

			})

			live.$on('location/delete', function(params) {

				context.commit('delete', params.id)

			})

		},

		save: function(context, data) {

			return (data.id) ? api.put('location/' + data.id, data) : api.post('location', data)

		},

		delete: function(context, data) {

			return api.delete('location/' + data.id, data)

		}

	}

}
