<template>

<div class="icon" :class="{'is-inline': inline}">

	<i :class="{[clsIcon]: true}"></i>

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['icon', 'inline'],

	data: function() {

		return {
			shortcut: {
				uncategorized: 'fa fa-question',
				reorder: 'fa fa-sort',
				analytics: 'fa fa-bar-chart',
				empty: 'fa fa-frown',
				share: 'fas fa-share',
				mobile: 'fas fa-mobile',
				desktop: 'fas fa-desktop',
				request: 'fas fa-edit',
				embed: 'fa fa-code',
				access: 'fa fa-users',
				'toolbar.bold': 'fa fa-bold',
				'toolbar.italic': 'fa fa-italic',
				'toolbar.underline': 'fa fa-underline',
				'toolbar.link': 'fa fa-link',
				'toolbar.unlink': 'fa fa-unlink',
				link: 'fa fa-unlink',
				unlink: 'fa fa-link',
				warning: 'fas fa-exclamation-triangle',
				loading: 'fas fa-spinner fa-spin',
				notice: 'fas fa-exclamation-circle',
				copy: 'fas fa-copy',
				clone: 'fas fa-clone',
				'remove.text': 'fas fa-unlink',
				insert: 'fas fa-plus-circle',
				'term.type.1': 'fas fa-th-list',
				'term.type.2': 'fas fa-th',
				'term.type.3': 'fas fa-box-open',
				'term.type.4': 'fas fa-map-marker-alt',
				'text.type.1': 'fas fa-heading',
				'text.type.2': 'fas fa-check',
				'text.type.3': 'fas fa-ban',
				'text.type.4': 'fas fa-check-square',
				'text.type.5': 'fas fa-star',
				'text.type.6': 'fas fa-map-marker',
				'text.type.7': 'fas fa-thumbtack',
				'text.type.8': 'fas fa-link',
				'text.type.9': 'fas fa-th',
				'text.type.10': 'fas fa-image',
				'text.type.11': 'fa fa-align-left',
				'text.type.12': 'fa fa-code',
				'text.type.13': 'fa fa-phone',
				'text.type.14': 'fa fa-list',
				'text.type.15': 'fa fa-exchange-alt',
				upload: 'fas fa-file-upload',
				remove: 'fas fa-times',
				add: 'fas fa-plus',
				noimage: 'fas fa-ban',
				excluded: 'fas fa-ban',
				search: 'fas fa-search',
				customise: 'fas fa-pen',
				cancel: 'fas fa-times',
				tick: 'fas fa-check',
				invisible: 'fas fa-eye-slash',
				categories: 'fas fa-th-list',
				groups: 'fas fa-th',
				items: 'fas fa-wine-bottle',
				locations: 'fas fa-map-marker-alt',
				texts: 'fas fa-pen',
				terms: 'fas fa-search',
				settings: 'fas fa-cog',
				logout: 'fas fa-sign-out-alt',
				create: 'fas fa-plus-circle',
				filter: 'fas fa-filter',
				'chevron.left': 'fas fa-chevron-left',
				'chevron.right': 'fas fa-chevron-right',
				'caret.down': 'fas fa-caret-down',
				save: 'fas fa-save',
				undo: 'fas fa-undo',
				delete: 'fas fa-trash'
			}
		}

	},

	computed: {

		clsIcon: function() {

			return this.shortcut[this.icon]

		}

	}

}

</script>

<style scoped>

.icon.is-inline {
	display: inline-block;
}

</style>
