<template>

<div class="texty text-block" v-html="text" />

</template>

<script>

export default {

	props: ['item'],

	computed: {

		text: function() {

			return this.item.content

		}

	}

}

</script>

<style scoped>

.texty {
	color: #707070;
	font-size: 18px;
	font-weight: 400;
	flex-grow: 1;
	line-height: 24px;
	margin-bottom: 5px;
}

.texty >>> em,
.texty >>> i {
	font-style: italic;
}

.texty >>> a {
	color: #4082d3;
}

</style>
