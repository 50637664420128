<template>

<div class="input" :class="{'is-stacked': stacked, 'is-error': hasError}">

	<div class="input-inner">

		<div class="input-label" v-if="label">
			
			{{ label }}

			<app-icon icon="notice" class="input-label-icon" v-if="hasError" v-tooltip="errorTip" />
			
		</div>

		<div class="input-content" :class="{'is-noline': noLine, 'is-disabled': disabled, 'is-ready': isReady}">

			<slot></slot>

		</div>

	</div>

	<div class="input-notes">{{ notes }}</div>

</div>

</template>

<script>

export default {

	props: ['label', 'validation', 'stacked', 'noLine', 'isReady', 'notes', 'disabled'],

	computed: {

		hasError: function() {

			return (this.validation) ? this.validation.$error && this.validation.$dirty : false

		},

		errorTip: function() {

			return 'Please enter a valid value'

		}

	}

}

</script>

<style scoped>

.input {
	margin-bottom: 10px;
}

.input-inner {
	display: flex;
}

.is-mobile .input-inner {
	flex-direction: column;
}

.input.is-stacked .input-inner {
	flex-direction: column;
}

.input:last-child {
	margin-bottom: 0px;
}

.input-label {
	width: 150px;
	font-size: 16px;
	font-weight: 400;
	color: #0b234e;
	flex-shrink: 0;
	line-height: 40px;
}

.input.is-error .input-label {
	color: #f25151;
}

.input-label-icon {
	position: absolute;
	right: 10px;
	top: 0px;
	line-height: 40px;
}

.is-tablet .input-label {
	font-size: 14px;
}

.is-mobile .input-label {
	width: 100%;
	font-size: 14px;
	line-height: 14px;
	padding: 10px 0px 5px 0px;
}

.input-content {
	flex-grow: 1;
}

.input-content.is-disabled {
	opacity: 0.5;
	pointer-events: none;
}

.input-content.is-ready {
	border-color: #4082d3;
}

.input-content.is-noline {
	border-bottom: 0px;
}

.input-notes {
	font-size: 12px;
	line-height: 16px;
	margin-top: 4px;
	color: #707070;
	padding-left: 160px;
}

.is-mobile .input-notes {
	padding-left: 0px;
}

</style>
