import _ from 'underscore'
import Vue from 'vue'
import api from '@/api'
import live from '@/live'

export default {

	namespaced: true,

	state: {
		data: {
			requests: []
		}
	},

	getters: {

		all: function(state) {

			return _.sortBy(state.data.requests, function(item) {

				return item.date

			})

		},

		find: function(state) {

			return function(id) {

				return _.findWhere(state.data.requests, {
					id: parseInt(id)
				})

			}

		}

	},

	mutations: {

		set: function(state, data) {

			state.data.requests = data.requests

		},

		update: function(state, data) {

			var index = _.findIndex(state.data.requests, {
				id: data.id
			})

			if (index !== -1) {
				
				Vue.set(state.data.requests, index, data)

			} else {

				state.data.requests.push(data)

			}

		},

		delete: function(state, data) {

			var index = _.findIndex(state.data.requests, {
				id: data.id
			})

			state.data.requests.splice(index, 1)

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)

			live.$on('request/add', function(params) {

				context.commit('update', params)

			})

			live.$on('request/update', function(params) {

				context.commit('update', params)

			})

			live.$on('request/delete', function(params) {

				context.commit('delete', params.id)

			})

		},


		save: function(context, data) {

			return (data.id) ? api.put('request/' + data.id, data) : api.post('request', data)

		},

		delete: function(context, data) {

			return api.delete('request/' + data.id, data)

		}

	}

}
