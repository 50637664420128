<template>

<div class="tick text-block">

	<div class="tick-icon" v-if="!category"></div>
	<app-item-badge v-if="category" :route="(category.id) ? 'Category' : 'Category.Groups'" :params="{category: category.id}" :image="(category.id) ? category.icon : false" :icon="category.icon" :color="category.background" :tooltip="category.title" />
	<h1 class="tick-text" v-html="title"></h1>

</div>

</template>

<script>

export default {

	props: ['item'],

	computed: {

		title: function() {

			return this.item.content || this.group.title

		},

		group: function() {

			return (this.item.group) ? this.$store.getters['groups/find'](this.item.group) : {
				title: 'Sample text'
			}

		},

		category: function() {

			if (this.$route.params.group) {

				var group = this.$store.getters['groups/find'](this.$route.params.group)

				return (group) ? this.$store.getters['categories/find'](group.category) : false

			} else {

				return false

			}

		}

	}

}

</script>

<style scoped>

.tick {
	display: flex;
	flex-direction: row;
	margin-bottom: 5px;
	align-items: center;
}

.tick-group {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	flex-shrink: 0;
	background-size: 17px 17px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.tick-icon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	flex-shrink: 0;
	background-size: 17px 17px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url(~@/assets/text/tick.png);
}

.tick-text {
	color: #707070;
	flex-grow: 1;
	margin-top: 4px;
	font-weight: bold;
	font-size: 22px;
}

.tick >>> a {
	color: #4082d3;
}

</style>
