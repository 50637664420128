<template>

<div class="tick text-block">

	<div class="tick-wrapper">

		<div class="tick-item" v-for="(check, index) in checks" :key="index">

			<div class="tick-icon"></div>
			<h3 class="tick-text" v-html="check"></h3>

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['item'],

	computed: {

		checks: function() {

			return this.item.content.split(/\r?\n/)

		}

	}

}

</script>

<style scoped>

.tick-wrapper {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr 1fr;
}

.is-desktop .tick-wrapper {
	grid-template-columns: 1fr 1fr 1fr;
}

.tick-item {
	display: flex;
	flex-direction: row;
}

.tick-icon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	flex-shrink: 0;
	background-size: 17px 17px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url(~@/assets/text/check.png);
}

.tick-text {
	color: #707070;
	font-size: 18px;
	flex-grow: 1;
	line-height: 24px;
	margin-bottom: 5px;
}

</style>
