<template>

<div class="embed text-block" v-html="text" />

</template>

<script>

export default {

	props: ['item'],

	created: function() {

		this.$nextTick(function() {

			window.twttr.widgets.load()
			window.instgrm.Embeds.process()

		})

	},

	watch: {

		text: function() {

			this.$nextTick(function() {

				window.twttr.widgets.load()
				window.instgrm.Embeds.process()

			})

		}

	},

	computed: {

		text: function() {

			return this.item.content

		}

	}

}

</script>

<style scoped>

.embed >>> iframe {
	max-width: 100%;
}

</style>
