<template>
	
<router-link :to="{name: route, params: params}" class="count" :class="{'is-empty': !count}" v-tooltip="tooltip">

	{{ count }}

</router-link>

</template>

<script>

export default {

	props: ['route', 'params', 'count', 'tooltip']

}

</script>

<style scoped>

.count {
	background-color: #4082d3;
	color: #fff;
	padding: 6px 8px;
	margin-right: 20px;
	border-radius: 4px;
}

.count.is-empty {
	background-color: #aaa;
}

</style>
