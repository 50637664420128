import Pusher from 'pusher-js'
import pubsub from '@/pubsub'
import store from '@/store'
import _ from 'underscore'

Pusher.logToConsole = true

var connection = false
var channels = {}
var logs = []

export default {

	init: function() {

		if (!connection) connection = new Pusher('ef8d15097a95953ddd11', {
			cluster: 'us3'
		})

	},

	$received: function(id) {

		var received = _.contains(logs, id)

		if (!received) logs.push(id)

		return received

	},

	$on: function(name, handler) {

		var channelName = name.replace(/guide\//, store.getters['guide/key'] + '/').replace(/\//g, '-')

		channels[name] = connection.subscribe(channelName)

		channels[name].bind('event', function(e) {

			if (!this.$received(e.id)) handler(e.params)

		}.bind(this))

		pubsub.$on(name, handler)

	},

	$off: function(name, handler) {

		var channelName = name.replace(/guide\//, store.getters['guide/key'] + '/').replace(/\//g, '-')

		channels[name].unbind('event', function(e) {

			if (!this.$received(e.id)) handler(e.params)

		}.bind(this))

		pubsub.$off(name, handler)

		connection.unsubscribe(channelName)

	}

}