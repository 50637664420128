<template>

<com-screen />

</template>

<script>

export default {

	components: {
		'com-screen': () => import('./components/Screen')
	},

	created: async function() {

		await this.$store.dispatch('init')

		if (!this.$store.getters['key']) {
			
			this.$router.push({
				name: 'Login'
			})

		} else if(!this.$store.getters['context']) {

			this.$router.push({
				name: 'Dashboard'
			})

		}

	}

}

</script>

<style>

</style>
