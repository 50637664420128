<template>

<app-input :label="label" :stacked="stacked" :no-line="noLine" :notes="notes" :disabled="disabled" :validation="validation">

	<input v-if="!autogrow && !rich" :type="(password) ? 'password' : 'text'" class="text" v-bind:value="value" :maxlength="maxlength" v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder" />
	<textarea v-if="autogrow && !rich" v-autogrow class="text" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder" />
	
	<div class="rich text" v-if="rich">

		<div class="rich-toolbar">

			<div class="rich-toolbar-item" v-on:click="editor.chain().focus().toggleBold().run()"><app-icon icon="toolbar.bold" /></div>
			<div class="rich-toolbar-item" v-on:click="editor.chain().focus().toggleItalic().run()"><app-icon icon="toolbar.italic" /></div>
			<div class="rich-toolbar-item" v-on:click="editor.chain().focus().toggleUnderline().run()"><app-icon icon="toolbar.underline" /></div>
			<div class="rich-toolbar-item" v-on:click="setLink"><app-icon icon="toolbar.link" /></div>
			<div class="rich-toolbar-item" v-on:click="editor.chain().focus().unsetLink().run()" v-if="editor.isActive('link')"><app-icon icon="toolbar.unlink" /></div>

		</div>

		<div class="rich-link" v-if="is.linking">

			<input ref="link" v-on:blur="is.linking = false" v-on:keydown.enter="onEnter" v-on:keydown.esc="is.linking = false" type="text" v-model="url" placeholder="Enter full URL..." />

		</div>

		<div class="rich-content">

			<editor-content :editor="editor" />

		</div>

	</div>

	<app-icon icon="loading" v-if="loading" class="text-loading" />	

</app-input>

</template>

<script>

import { Editor, EditorContent } from '@tiptap/vue-2'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'

export default {

	props: ['label', 'rich', 'loading', 'toolbar', 'password', 'validation', 'autogrow', 'disabled', 'value', 'noLine', 'stacked', 'placeholder', 'notes', 'maxlength'],

	components: {
		EditorContent
	},

	data: function() {

		return {
			editor: null,
			url: '',
			is: {
				linking: false
			}
		}

	},

	watch: {

		value: function(n) {

			const isSame = this.editor.getHTML() === n

			if (isSame) return

			this.editor.commands.setContent(n, false)

		}

	},

	mounted: function() {

		this.editor = new Editor({
			content: this.value,
			extensions: [
				StarterKit,
				Link.configure({
					openOnClick: false,
				}),
				Underline
			],
			onUpdate: function() {

				this.$emit('input', this.editor.getHTML())

			}.bind(this)
		})

	},

	beforeDestroy: function() {

		this.editor.destroy()

	},

	methods: {

		onEnter: function() {

			if (this.url) {

				this.editor.chain().focus().extendMarkRange('link').setLink({ href: this.url }).run()

			} else {

				this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
				
			}

			this.$refs.link.blur()

		},

		setLink: function() {

			this.is.linking = true
			this.url = this.editor.getAttributes('link').href
			
			this.$nextTick(function() {
				
				this.$refs.link.focus()

			})

		}

	}

}

</script>

<style scoped>

.text {
	line-height: 36px;
	font-size: 16px;
	width: 100%;
	padding: 0px 10px;
	color: #333;
	background-color: #fff;
	border-radius: 12px;
	border: 2px solid #eee;
}

textarea.text {
	line-height: 20px;
	box-sizing: initial;
	width: calc(100% - 24px);
	padding: 10px;
	resize: none;
}

.text:focus {
	border-color: #4082d3;
}

.is-device .text {
	font-size: 14px;
}

.is-mobile .text {
	padding: 0px;
}

.rich {
	padding: 0px;
}

.rich-toolbar {
	display: flex;
	padding: 10px 10px;
}

.rich-toolbar-item {
	width: 24px;
	height: 24px;
	background-color: #eee;
	line-height: 27px;
	font-size: 12px;
	text-align: center;
	border-radius: 4px;
	margin-right: 4px;
	cursor: pointer;
}

.rich-link {
	padding: 0px 10px;
	border-top: 2px solid #eee;
	border-bottom: 2px solid #eee;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
}

.rich-link input {
	font-size: 14px;
	height: 40px;
	flex-grow: 1;
}

.rich-content {
	padding: 0px 10px 10px 10px;
	font-size: 18px;
    line-height: 24px;
}

.rich-content >>> em,
.rich-content >>> i {
	font-style: italic;
}

.rich-content >>> a {
	color: #4082d3;
}

.text-loading {
	position: absolute;
	right: 10px;
	top: 0px;
	line-height: 40px;
	font-size: 16px;
	color: #4082d3;
}

</style>
