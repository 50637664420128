<template>

<app-input class="file" :validation="validation" :class="{'is-empty': !value}" :label="label" :stacked="stacked" :no-line="noLine" :notes="notes" :disabled="disabled">

	<div class="file-preview" :class="{'is-previewing': value}">

		<template v-if="!value">No file uploaded.</template>
		<template v-if="value">{{ filename || value }}</template>

	</div>

	<div class="file-button is-select" v-tooltip="(value) ? 'Change file' : 'Select file'" v-on:click="onSelectClick">

		<app-icon icon="upload" />

	</div>

	<div v-if="value" class="file-button is-remove" v-tooltip="'Remove file'" v-on:click="onRemoveClick">

		<app-icon icon="delete" />

	</div>

	<input ref="file" type="file" accept=".pdf" v-on:change="onFileSelected" class="file-file" />

</app-input>

</template>

<script>

export default {

	props: ['validation', 'label', 'disabled', 'value', 'noLine', 'stacked', 'notes'],

	data: function() {
	
		return {
			filename: ''
		}

	},

	methods: {

		onSelectClick: function() {

			this.$refs.file.click()
				
		},

		onRemoveClick: function() {

			this.$emit('input', '')
				
		},

		onFileSelected: function(e) {

			var file = e.target.files[0]

			var reader = new FileReader()

			reader.onloadend = function() {

				this.filename = file.name
				this.$emit('input', reader.result)

			}.bind(this)

			reader.readAsDataURL(file)
			
		}

	}

}

</script>

<style scoped>

.file >>> .input-content {
	display: flex;
}

.file-preview {
	border: 2px solid #eee;
	width: 100%;
	min-height: 40px;
	background-color: #eee;
	border-radius: 12px 0px 0px 12px;
	flex-grow: 1;
}

.file-preview.is-previewing {
	height: 44px;
	border-width: 10px;
	background-repeat: no-repeat;
	border-radius: 12px;
	padding-right: 100px;
	display: flex;
	align-items: center;
}

.file.is-empty .file-preview {
	color: #333;
	padding: 0px 10px;
	line-height: 36px;
	font-size: 16px;
}

.file-button {
	flex-shrink: 0;
	border: 2px solid #eee;
	border-radius: 0px 12px 12px 0px;
	border-left-width: 0px;
	background-color: #fff;
	width: 36px;
	line-height: 36px;
	text-align: center;
	color: #4082d3;
	cursor: pointer;
	font-size: 16px;
	transition: all 100ms linear;
	background-color: #4082d3;
	border-color: #4082d3;
	color: #fff;
}

.file:not(.is-empty) .file-button {
	position: absolute;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	border-width: 0px;
	line-height: 36px;
	right: 4px;
	bottom: 4px;
}

.file:not(.is-empty) .file-button.is-select {
	right: 44px;
}

.file:not(.is-empty) .file-button.is-remove {
	right: 4px;
	background-color: #c94141;
}

.file-file {
	display: none;
}

</style>
