<template>

<div class="notice">

	<app-icon icon="notice" class="notice-icon" />

	<div class="notice-text">
		
		<slot></slot>

	</div>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.notice {
	padding: 16px 20px;
	background-color: #90b0d7;
	color: #0b234e;
	display: flex;
	align-items: center;
}

.notice-icon {
	font-size: 48px;
	margin-right: 20px;
}

.notice-text {
	font-weight: 300;
	font-size: 14px;
	line-height: 18px;
}

</style>
