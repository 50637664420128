import api from '@/api'
import live from '@/live'

export default {

	namespaced: true,

	state: {
		data: {
			key: '',
			name: '',
			initials: '',
			color: {
				fore: '',
				back: ''
			}
		}
	},

	getters: {

		get: function(state) {

			return state.data

		},

		key: function(state) {

			return state.data.key

		}

	},

	mutations: {

		set: function(state, data) {

			state.data = data.guide

		},

		update: function(state, data) {

			if (state.data.key === data.key) {

				state.data = data

			}

		},

	},	

	actions: {

		init: function(context, data) {

			context.commit('set', data)

			live.$on('guide/update', function(params) {

				context.commit('update', params)

			})

		},

		save: function(context, data) {

			return api.put('guide', data)

		},

		delete: function(context, data) {

			return api.delete('guide', data)

		}

	}	

}
