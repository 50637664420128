<template>

<app-input :label="label" :no-line="true" :notes="notes" :disabled="disabled" :validation="validation">

	<div class="toggle" :class="{'is-slim': slim, 'is-fullwidth': fullWidth, 'is-stacked': stacked}">
		<div class="toggle-item" v-for="(option, key) in finalOptions" :key="key" :class="{'is-active': isSelected(key)}" v-on:click="onClick(key)">
			{{ option }}
			<app-icon icon="tick" class="toggle-item-icon" v-if="isSelected(key) && multiple" />
		</div>
	</div>

</app-input>

</template>

<script>

export default {

	props: ['validation', 'label', 'value', 'multiple', 'stacked', 'notes', 'fullWidth', 'slim', 'placeholder', 'options', 'disabled'],

	computed: {

		finalOptions: function() {
			
			return (this.options) ? this.options : {
				1: 'Yes',
				0: 'No'
			}

		}

	},

	methods: {

		onClick: function(value) {

			if (this.multiple) {

				var original = this.$_.clone(this.value)

				if (this.$_.contains(original, parseInt(value))) {

					original.splice(original.indexOf(parseInt(value)), 1)

				} else {

					original.push(parseInt(value))

				}

				this.$emit('input', original)

			} else {

				this.$emit('input', parseInt(value))

			}

		},

		isSelected: function(value) {

			if (this.multiple) {

				return this.$_.contains(this.value, parseInt(value))

			} else {

				return this.value === parseInt(value)

			}

		}

	}

}

</script>

<style scoped>

.toggle {
	display: flex;
	flex-direction: row;
	height: 40px;
	border-radius: 12px;
}

.toggle.is-stacked,
.is-mobile .toggle {
	flex-direction: column;
	height: auto;
}

.toggle-item {
	font-size: 16px;
	color: #eee;
	border: 4px solid #eee;
	border-width: 2px 2px 2px 0px;
	color: #333;
	min-width: 60px;
	padding: 0px 10px;
	cursor: pointer;
	text-align: center;
	background-color: #fff;
	line-height: 36px;
}

.toggle.is-fullwidth .toggle-item {
	flex-grow: 1;
	flex-shrink: 0;
}

.toggle.is-stacked .toggle-item {
	text-align: left;
}

.toggle.is-stacked .toggle-item,
.is-mobile .toggle-item {
	border-width: 2px 2px 0px 2px;
}

.toggle.is-stacked .toggle-item,
.is-device .toggle-item {
	font-size: 14px;
}

.toggle.is-slim .toggle-item {
	width: auto;
	padding: 0px 20px;
}

.toggle-item.is-active {
	background-color: #4082d3;
	color: #fff;
	border-color: #4083d3;
}

.toggle-item:first-child {
	border-left-width: 2px;
	border-radius: 12px 0px 0px 12px;
}

.toggle.is-stacked .toggle-item:first-child,
.is-mobile .toggle-item:first-child {
	border-radius: 12px 12px 0px 0px;
}

.toggle-item:last-child {
	border-radius: 0px 12px 12px 0px;
	border-bottom-width: 2px;
}

.toggle.is-stacked .toggle-item:last-child,
.is-mobile .toggle-item:last-child {
	border-radius: 0px 0px 12px 12px;
	border-bottom-width: 2px;
}

.toggle-item:first-child:last-child {
	border-radius: 12px;
}

.toggle-item-icon {
	position: absolute;
	right: 10px;
	top: 2px;
}

</style>
