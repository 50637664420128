<template>

<div class="search" :class="{'is-focus': isFocus}" v-if="count || value">

	<input type="text" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" v-on:focus="isFocus = true" v-on:blur="isFocus = false" class="search-text" placeholder="Quick search..." />

	<app-icon icon="search" class="search-button" />

</div>

</template>

<script>

export default {

	props: ['value', 'count'],

	data: function() {

		return {
			isFocus: false
		}

	}

}

</script>

<style scoped>

.search {
	height: 48px;
	padding: 6px 0px;
	display: flex;
	margin-right: 10px;
}

.search-text {
	line-height: 30px;
	height: 34px;
	max-width: 160px;
	font-size: 14px;
	padding: 0px 10px;
	color: #333;
	flex-grow: 1;
	border: 2px solid #eee;
	border-radius: 12px 0px 0px 12px;
	border-right-width: 0px;
}

.search.is-focus .search-text {
	border-color: #4082d3;
}

.search-button {
	flex-shrink: 0;
	border: 2px solid #eee;
	border-radius: 0px 12px 12px 0px;
	border-left-width: 0px;
	width: 32px;
	height: 34px;
	font-size: 16px;
	text-align: center;
	color: #eee;
	line-height: 32px;
	pointer-events: none;
}

.search.is-focus .search-button {
	border-color: #4082d3;
	color: #4082d3;
}

</style>
