<template>

<div v-on:click="onClick" class="action" v-tooltip="tooltip" :class="{'is-primary': isPrimary, 'is-danger': danger, 'is-disabled': disabled}">

	<app-icon :icon="icon" class="action-icon" v-if="!loading" />
	<app-icon icon="loading" class="action-icon" v-if="loading" />
	<div class="action-count" v-if="count">{{ count }}</div>

</div>

</template>

<script>

export default {

	props: ['icon', 'tooltip', 'loading', 'disabled', 'danger', 'isPrimary', 'route', 'count', 'params'],

	methods: {

		onClick: function() {
			
			if (this.route) {

				this.$router.push({
					name: this.route,
					params: this.params
				})

			} else {

				this.$emit('click')

			}

		}

	}

}

</script>

<style scoped>

.action {
	width: 40px;
	height: 48px;
	font-size: 24px;
	line-height: 48px;
	text-align: center;
	color: #4082d3;
	cursor: pointer;
	transition: all 100ms linear;
}

.action.is-danger {
	color: #f25151;
}

.action.is-disabled {
	pointer-events: none;
	opacity: 0.25
}

.action-icon >>> .fa-plus-circle {
	font-size: 32px;
	line-height: 46px;
}

.action-count {
	background-color: #f25151;
	border-radius: 4px;
	position: absolute;
	width: 16px;
	height: 16px;
	right: -4px;
	top: -4px;
	color: #fff;
	line-height: 16px;
	font-weight: 500;
	font-size: 12px;
}

</style>
