<template>

<div class="item" :class="{'is-selected': selected}" v-on:click="onClick">

	{{ meta }}

	<slot></slot>

	<app-icon icon="chevron.right" class="item-chevron" />

</div>

</template>

<script>

export default {

	props: ['route', 'id', 'idName', 'params', 'selected', 'query'],

	computed: {

		routeParams: function() {

			if (this.params) {

				return this.params

			} else {

				var params = {}

				params[this.idName] = this.id

				return params

			}

		}

	},

	methods: {
	
		onClick: function(e) {

			if (e.shiftKey) {
			
				this.$emit('shiftclick')
			
			} else {
			
				this.$router.push({name: this.route, params: this.routeParams, query: this.query})
			
			}
		
		}
	
	}

}

</script>

<style scoped>

.item {
	display: flex;
	padding: 20px;
	border-bottom: 1px solid #eee;
	user-select: none;
	align-items: center;
	cursor: pointer;
	transition: background-color 100ms linear;
}

.item.is-selected {
	background-color: #e98b8b;
}

.item:hover {
	background-color: #2e58a41f;
}

.item.is-selected:hover {
	background-color: #c66464;
}

.item:last-child {
	border-bottom-width: 0px;
}

.item-chevron {
	font-size: 16px;
	color: #ccc;
}

</style>
