<template>

<div class="location text-block">

	<div class="location-logo" v-bgimage="location.logo">

		<app-icon icon="noimage" class="location-logo-missing" v-if="!location.logo" />

	</div>

	<div class="location-text">
			
		<h3>{{ location.title }}</h3>
		<h5>{{ services }}</h5>
			
	</div>

</div>

</template>

<script>

export default {

	props: ['item'],

	computed: {

		location: function() {

			return (this.item.location) ? this.$store.getters['locations/find'](this.item.location) : {
				title: 'Sample text'
			}

		},

		services: function() {

			var services = []

			this.$_.each(this.location.services, function(value) {

				services.push(this.$constants.locationServices[value])

			}.bind(this))

			return services.join(', ')

		}

	}

}

</script>

<style scoped>

.location {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 20px;
}

.location-logo {
	width: 64px;
	height: 64px;
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	margin-right: 20px;
	flex-shrink: 0;
}

.location-logo-missing {
	font-size: 18px;
	color: #999;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.location-text {
	flex-grow: 1;
}

.location-text h3 {
	color: #707070;
	font-size: 24px;
	flex-grow: 1;
	margin-bottom: 5px;
}

.location-text h5 {
	color: #707070;
	font-size: 16px;
}

</style>
