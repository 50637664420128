<template>

<div class="page" :class="{'is-locked': locked}">

	<template v-if="!loading">
	
		<slot></slot>

	</template>

	<app-icon class="page-loading" icon="loading" v-if="loading" />

</div>

</template>

<script>

export default {

	props: ['locked', 'loading']

}

</script>

<style scoped>

.page {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 54px;
}

.page.is-locked {
	pointer-events: none;
}

.page-loading {
	font-size: 32px;
	position: absolute;
	top: 50%;
	left: 50%;
	color: #4082d3;
	transform: translate(-50%, -50%);
}

</style>
