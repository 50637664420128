import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import cookies from 'js-cookie'
import live from '@/live'
import api from '@/api'

Vue.use(Vuex)

import modGuide from './store/guide'
import modCategories from './store/categories'
import modGroups from './store/groups'
import modItems from './store/items'
import modUser from './store/user'
import modTexts from './store/texts'
import modLocations from './store/locations'
import modRequests from './store/requests'
import modAccess from './store/access'

export default new Vuex.Store({

	modules: {
		guide: modGuide,
		categories: modCategories,
		groups: modGroups,
		items: modItems,
		texts: modTexts,
		user: modUser,
		locations: modLocations,
		requests: modRequests,
		access: modAccess
	},

	state: {
		ready: false,
		linked: false,
		mode: 'mobile',
		key: '',
		context: '',
		config: {
			base: '',
			preview: ''
		},
		stash: {}
	},

	getters: {

		user: function(state, getters) {

			return getters['user/get']

		},

		guide: function(state, getters) {

			return getters['guide/get']

		},

		categories: function(state, getters) {

			return getters['categories/all']

		},

		groups: function(state, getters) {

			return getters['groups/all']

		},

		items: function(state, getters) {

			return getters['items/all']

		},

		texts: function(state, getters) {

			return getters['texts/all']

		},

		access: function(state, getters) {

			return getters['access/all']

		},

		locations: function(state, getters) {

			return getters['locations/all']

		},

		requests: function(state, getters) {

			return getters['requests/all']

		},

		stash: function(state) {

			return state.stash

		},

		linked: function(state) {

			return state.linked

		},

		mode: function(state) {

			return state.mode

		},

		key: function(state) {

			return state.key

		},

		context: function(state) {

			return state.context

		},

		ready: function(state) {

			return state.ready

		},

		config: function(state) {

			return state.config

		}

	},

	mutations: {

		stash: function(state, e) {

			state.stash[e.name] = e.data

		},

		linked: function(state, value) {

			cookies.set('linked', value)
			
			state.linked = value

		},

		mode: function(state, value) {

			cookies.set('mode', value)
			
			state.mode = value

		},

		ready: function(state, value) {

			state.ready = value

		},

		config: function(state, value) {

			Vue.set(state, 'config', value)

		},

		key: function(state, value) {

			state.key = value

			if (value) {
				
				cookies.set('key', value)

			} else {

				cookies.remove('key')

			}

			axios.defaults.headers.common['Authorization'] = value

		},

		context: function(state, value) {

			state.context = value

			if (value) {
				
				cookies.set('context', value)

			} else {

				cookies.remove('context')

			}

			axios.defaults.headers.common['rbc-guide'] = value

		}

	},

	actions: {

		init: async function(context) {

			if (cookies.get('key') !== undefined) context.commit('key', cookies.get('key'))
			if (cookies.get('context') !== undefined) context.commit('context', cookies.get('context'))
			if (cookies.get('linked') !== undefined) context.commit('linked', cookies.get('linked'))
			if (cookies.get('mode') !== undefined) context.commit('mode', cookies.get('mode'))

			if (context.getters['key'] && context.getters['context']) await context.dispatch('load')
					
		},

		load: function(context) {

			return new Promise(function(resolve, reject) {
				
				api.get('data').then(async function(data) {

					live.init()

					await context.dispatch('user/init', data)
					await context.dispatch('guide/init', data)
					await context.dispatch('categories/init', data)
					await context.dispatch('groups/init', data)
					await context.dispatch('items/init', data)
					await context.dispatch('texts/init', data)
					await context.dispatch('locations/init', data)
					await context.dispatch('requests/init', data)
					await context.dispatch('access/init', data)

					context.commit('config', data.config)
					
					context.commit('ready', true)

					resolve()

				}, function() {

					context.commit('key', false)

					reject()

				})

			})

		},

		login: function(context, data) {

			return new Promise(function(resolve, reject) {
				
				api.post('login', data).then(async function(data) {

					context.commit('key', data.key)
					context.commit('context', false)

					resolve()
						
				}, function() {

					reject()

				})

			})

		},

		logout: function(context) {

			context.commit('key', false)
			context.commit('context', false)
			context.commit('ready', false)

		}

	}

})
