<template>

<app-input class="slug" :label="label" :validation="validation" :stacked="stacked" :no-line="noLine" :is-ready="value.length" :notes="notes" :disabled="disabled">

	<input type="text" ref="input" :disabled="!isCustom" class="slug-text" :class="{'is-disabled': !isCustom}" v-bind:value="value" :maxlength="maxlength" v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder" />

	<div class="slug-button" v-if="!isCustom" v-tooltip="'Customise slug'" v-on:click="onCustomClick">

		<app-icon icon="customise" />

	</div>

	<div class="slug-button is-active" v-if="isCustom" v-tooltip="'Reset slug'" v-on:click="onResetClick">

		<app-icon icon="cancel" />

	</div>

</app-input>

</template>

<script>

import slugify from 'slugify'

export default {

	props: ['validation', 'label', 'source', 'password', 'disabled', 'value', 'noLine', 'stacked', 'placeholder', 'notes', 'maxlength'],

	data: function() {

		return {
			is: {
				custom: false
			}
		}

	},

	computed: {

		autoSlug: function() {

			return slugify(this.source, {
				lower: true,
				remove: /[*+~.()'"!:@]/g,
				strict: true
			})

		},

		isCustom: function() {

			return this.is.custom

		}

	},

	created: function() {

		if (this.autoSlug !== this.value) this.is.custom = true

	},

	watch: {

		source: function() {

			this.$emit('input', this.autoSlug)

		}

	},

	methods: {
		
		onCustomClick: function() {

			this.is.custom = true

			this.$nextTick(function() {
				
				this.$refs.input.focus()

			})

		},

		onResetClick: function() {

			this.is.custom = false
			this.$emit('input', this.autoSlug)

		}

	}

}

</script>

<style scoped>

.slug >>> .input-content {
	display: flex;
	flex-direction: row;
}

.slug-text {
	line-height: 36px;
	font-size: 16px;
	width: 100%;
	padding: 0px 10px;
	color: #333;
	background-color: #fff;
	border-radius: 12px 0px 0px 12px;
	border: 2px solid #eee;
	border-right-width: 0px;
	flex-grow: 1;
}

.slug-text.is-disabled {
	background-color: #eee;
}

.slug-text:focus {
	border-color: #4082d3;
}

.slug-button {
	flex-shrink: 0;
	border: 2px solid #eee;
	border-radius: 0px 12px 12px 0px;
	border-left-width: 0px;
	background-color: #fff;
	width: 36px;
	line-height: 36px;
	text-align: center;
	color: #4082d3;
	cursor: pointer;
	font-size: 16px;
	transition: all 100ms linear;
	background-color: #4082d3;
	border-color: #4082d3;
	color: #fff;
}

</style>
